import Vue from 'vue'
import VueI18n from 'vue-i18n'
import it from '../i18n/it.json'

Vue.use(VueI18n)

const messages = {
  it
}

const defaultLocale = 'it'

const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: 'it'
})

export default i18n
