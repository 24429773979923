import Vue from 'vue'
import EventBus from "@/plugins/bus";
import i18n from '@/plugins/vue-i18n'

const state = {
    user: null,
    token: null,
    token_stat: null
}

const actions = {

    async me({commit}) {
        let user = null

        try {
            const {data} = await Vue.axios({
                method: 'GET',
                url: '/private/user/me'
            })

            user = data
            commit('auth/set_token', user.jwt_token_authenticator, {root: true})
        } catch (err) {
        }

        commit('auth/set_user', user, {root: true})

        return user
    },

    async login({commit}, credentials) {
        let user = null

        try {
            const {data} = await Vue.axios({
                method: 'POST',
                url: '/public/user/login',
                data: credentials
            })

            user = data
            commit('auth/set_token', user.jwt_token_authenticator, {root: true})
        } catch (err) {
        }

        commit('auth/set_user', user, {root: true})

        return user
    },

    /**
     * Login del usuario mediante email y token
     * @param commit
     * @param credentials
     * @returns {Promise<null>}
     */
    async autologin({commit}, credentials) {
        let user = null

        try {
            const {data} = await Vue.axios({
                method: 'POST',
                url: '/public/user/autologin',
                data: credentials
            })

            user = data
            commit('auth/set_token', user.jwt_token_authenticator, {root: true})
        } catch (err) {
        }

        commit('auth/set_user', user, {root: true})

        return user
    },

    logout({commit}) {
        commit('auth/set_token', null, {root: true})
        commit('auth/set_user', null, {root: true})
    },

    async register({commit}, user_data) {

        let user = null

        try {
            const reponse = await Vue.axios({
                method: 'POST',
                url: '/public/user',
                data: user_data
            })
            user = reponse.data
            commit('auth/set_token', user.jwt_token_authenticator, {root: true})
        } catch (err) {
            let text = i18n.t('Se ha producido un error en el registro')
            if (err.response.status === 409) { // Confict. El usuario ya existe
                // text = `El usuario ${user_data['email']} ya está registrado`
                text = i18n.t('El usuario {email} ya está registrado', {email: user_data['email']})
            }
            EventBus.$emit('show_message', {
                title: 'Error',
                text,
                style: 'error'
            })
        }

        commit('auth/set_user', user, {root: true})

        return user
    },

    async recover_password({commit}, contact) {
        try {
            const data = await Vue.axios({
                method: 'GET',
                url: '/public/user/password/recuperar',
                params: {
                    usuario: contact
                }
            })
            return data.status == 202;
        } catch (err) {
            return false
        }
    }
}

const mutations = {
    set_user(state, user) {
        state.user = user
    },
    set_token(state, token) {
        state.token = token
    },
    set_token_stat(state, token) {
        state.token_stat = token
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
